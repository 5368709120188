import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import * as Actions from "../../actions/events.types";
import axios from 'axios';

const Events = () => {
  const [events, setEvents] = React.useState(null);
  const appState = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const eventsData = appState.events;
    if (eventsData?.isSuccess && !isEmpty(eventsData?.data)) {
      setEvents(eventsData.data);
    }
  }, [appState.events]);

  const fetchInitData = useCallback(() => {
    dispatch(Actions.events());
  }, [dispatch]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);


  const Authenticate = (e) => {
    e.preventDefault();
    const baseURL = "https://wavemaker.fisdev.local/run-zj0lxhm99q/TestOFGLogin_master/services/login/exchangeToken"
    axios
      .post(baseURL)
      .then((response) => {
        // check response.
      });
  };

  return (
    <div className="flex">
      <div>
      {events &&
        events.map((d, idx) => (
          <div key={idx} className="container">
            <div>
              <span>{d.name}</span>
            </div>
          </div>
        ))}
      </div>
      <div>
      <form id='' name='' onSubmit={Authenticate}>
        <button>Submit</button>
      </form>
      </div>
    </div>
  );
};

export default Events;
