import { 
    GET_EVENTS,
    GET_EVENTS_SUCCESS,
    GET_EVENTS_FAILURE,
} from '../store/constants';

export const events = () => ({
    type: GET_EVENTS
});

  
export const eventsSuccess = (data) => ({
    type: GET_EVENTS_SUCCESS,
    payload: data,
});
  
export const eventsFailure = (error) => ({
    type: GET_EVENTS_FAILURE,
    payload: error,
});
  
