import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Events from "./components/Events";
import store from "./store";
import "./App.css";

// Adding comment for git test

const App = () => {
  return (
      <Provider store={store}>
        <BrowserRouter>
            <Events />
        </BrowserRouter>
      </Provider>
  );
};

export default App;
