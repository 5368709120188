import axios from 'axios';

const createAxiosInstance = (serviceEndPointBaseURL) => {
  return axios.create({
    baseURL: `${serviceEndPointBaseURL}`,
    timeout: 10000,
    withCredentials: true,
  });
};

const API = createAxiosInstance('localhost:3001');

export default API;
