import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
} from '../store/constants';
  
const initialState = {
    reqStatus: null,
    isLoading: false,
    data: null,
    isSuccess: false,
    error: null,
};
  
export function eventsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_EVENTS:
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
            };
  
        case GET_EVENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                reqStatus: action.type,
                isSuccess: true,
            };
  
        case GET_EVENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                reqStatus: action.type,
                error: action.payload,
            };
        default:
            return state;
    }
}
  