import { put, takeLatest, call } from 'redux-saga/effects';

import { GET_EVENTS } from '../../store/constants';
import * as Actions from '../../actions/events.types';
import API from "../../api";

function* workerGetEventsSaga( payload ) {
    try {
        const events = [{"name": "Events1"}, {"name": "Events2"}] //yield call(API, url);
        yield put(Actions.eventsSuccess(events));
    } catch (error) {
        yield put(Actions.eventsFailure(error));
    }
}

export default function* watchGetEventsSaga() {
    yield takeLatest(GET_EVENTS, workerGetEventsSaga);
}